<template>
  <div class="box_header" v-if="accountList.length > 0">
    <div class="header">
      <img v-if="!accountInfo.avatar" src="@/assets/img/image/blank_headPic.png" alt="" />
      <img v-else :src="accountInfo.avatar" alt="" />
    </div>
    <div class="account_info" style="margin-left: 10px">
      <div class="info">
        <div class="name_select">
          <span class="name">{{ accountInfo.nickname }}</span>
          <div class="switch_account">
            <el-popover
              popper-class="popver_account"
              style="padding-right: 0px !important"
              class="popver"
              ref="popover"
              placement="bottom"
              width="260"
              trigger="click"
            >
              <div class="account_list">
                <div class="account_item" v-for="(item, index) in accountList" @click="selectAcount(item, index)" :key="item.id">
                  <div class="avatar">
                    <img v-if="!item.avatar" src="@/assets/img/image/blank_headPic.png" alt="" />
                    <img v-else :src="item.avatar" alt="" />
                  </div>
                  <div class="info">
                    <span class="name">{{ item.nickname }}</span>
                    <div v-if="item.is_on_line === 0" style="display: flex; align-items: center">
                      <span style="display: flex; align-items: center"
                        ><span style="display: flex; align-items: center"
                          ><span
                            style="
                              background-color: #fe2c55;
                              width: 4px;
                              height: 4px;
                              display: inline-block;
                              border-radius: 100%;
                              margin-right: 5px;
                            "
                          ></span></span
                        >登录失败</span
                      >
                      <el-button class="re_bind_douyin"><span @click="reBindDouyin()">重新登陆</span></el-button>
                    </div>
                    <div v-else>
                      <span style="display: flex; align-items: center"
                        ><span
                          style="
                            background-color: #39b54a;
                            width: 4px;
                            height: 4px;
                            display: inline-block;
                            border-radius: 100%;
                            margin-right: 5px;
                          "
                        ></span
                        >登录成功</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div slot="reference" style="cursor: pointer; font-size: 18px">切换账号<i class="el-icon-arrow-down"></i></div>
            </el-popover>
          </div>
        </div>
        <span class="number"
          >手机号: <span>{{ accountInfo.mobile }}</span></span
        >
      </div>
    </div>

    <div class="account_info" style="margin-left: 120px">
      <div class="item">
        <span>作品</span>
        <span class="number">{{ accountInfo.aweme_count }}</span>
      </div>
      <div class="item">
        <span>粉丝</span>
        <span class="number">{{ accountInfo.follower_count }}</span>
      </div>
      <div class="item">
        <span>获赞</span>
        <span class="number">{{ accountInfo.favoriting_count }}</span>
      </div>
    </div>

    <div class="header-slot-right">
      <slot name="header-right"></slot>
    </div>
  </div>
  <div class="no_data" v-else>
    <div class="left">
      <img src="../../assets/img/image/image.png" alt="" />
      <span style="margin-left: 20px">暂无账号</span>
    </div>
    <el-button @click="reBindDouyin(1)"> 添加账号 </el-button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      accountInfo: {
        nickname: "",
        mobile: "",
        avatar: "",
        aweme_count: "", //总作品
        id: null,
        follower_count: null, //总粉丝数
        favoriting_count: null, //获赞数
      },
      accountList: [],
      selectAccountIndex: 0,
      visible: false,
    };
  },
  mounted() {
    this.getAccountList();
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    reBindDouyin(item) {
      // console.log("item", item);
      this.$httpStudent.axiosGetBy(
        this.$api.tikhoknew_authorization,
        {},
        (res) => {
          if (res.code === 200) {
            // console.log("url", res.data.url);
            window.open(res.data.url, "抖音授权扫码", "channelmode=0,directories=0,width=1000,height=500,top=200,left=200");
            // this.dialogAddAccount = false;
            // this.AddAccountManageModule = false;
          } else {
            this.$message({
              type: "error",
              message: res.message,
              duration: 1000,
            });
          }
        },
        (err) => {
          // console.log(err);
        }
      );
    },
    getAccountList() {
      let params = {
        page: 1,
        pageSize: 10,
        paging: 0,
      };
      this.$httpStudent.axiosGetBy(this.$api.get_account, params, (res) => {
        if (res.code == 200) {
          this.accountList = res.data.data;
          if (localStorage.getItem("account") == null) {
            if (this.$route.query && this.$route.query.accountId) {
              const accountInfo = res.data.data.find((item) => item.id == this.$route.query.accountId);
              this.accountInfo = accountInfo;
            } else {
              this.accountInfo = res.data.data[0];
            }

            localStorage.setItem("account", JSON.stringify(this.accountInfo));
            localStorage.setItem("accountIndex", 0);
          } else {
            this.accountInfo = JSON.parse(localStorage.getItem("account"));
            this.selectAccountIndex = localStorage.getItem("accountIndex");
          }
          this.$emit("selectAccountData", this.accountInfo);
        }
      });
    },
    selectAcount(item, index) {
      this.selectAccountIndex = index;
      this.accountInfo = item;
      this.$emit("selectAccountData", item);
      localStorage.setItem("account", JSON.stringify(item));
      localStorage.setItem("accountIndex", index);
      this.$refs.popover.doClose()
    },
    handleScroll() {
      if (this.$refs.popper) {
        this.$refs.popper.updatePopper();
      }
    },

    // 格式化数量
    formatCount(value) {
      let count = parseFloat(value);
      if (isNaN(count)) return 0;

      let negativeFlag = count < 0;
      if (negativeFlag) count = -count;

      if (count < 10000) return `${negativeFlag ? "-" : ""}${count.toString()}`;
      else return `${negativeFlag ? "-" : ""}${(count / 10000).toFixed(2)}万`;
    },
  },
  beforeDestroy() {
    // 删除 localStorage 中的项
    localStorage.removeItem("account");
    localStorage.removeItem("accountIndex");
  },
};
</script>
<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}

.box_header {
  width: 100%;
  height: 112px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  box-sizing: border-box;
  padding: 24px;
  box-sizing: border-box;
  position: relative;

  .header {
    width: 64px;
    height: 64px;
    border-radius: 100%;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }

  .info {
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 22px;
    color: #000000;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .name {
      max-width: 217px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }

    .number {
      color: #7a7e88;
      font-size: 20px;
    }
  }

  .switch_account {
    width: 100px;
    height: 100%;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #888888;
    text-align: left;
    font-style: normal;
    text-transform: none;
    flex-shrink: 0;
    margin-left: 20px;
    margin-top: 4px;

    i {
      margin-left: 5px;
    }
  }

  .account_info {
    display: flex;
    height: 100%;

    .name_select {
      display: flex;
      align-items: center;
    }

    .item {
      width: 160px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #4d4f56;

      span {
        display: inline-block;
      }

      .number {
        font-family: Douyin Sans, Douyin Sans;
        font-weight: bold;
        font-size: 24px;
        color: #171a1e;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
  }
  .header-slot-right {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.re_bind_douyin {
  width: 60px;
  height: 22px;
  background: #f2f2f4;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #594fee;
  border: none;
  margin-left: 10px;
}

.account_list {
  .account_item {
    width: 260px;
    height: 69px;
    padding: 12px 0px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;

    .avatar {
      width: 45px;
      height: 45px;
      border-radius: 100%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }

    .info {
      width: 170px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 12px;
      box-sizing: border-box;

      .name {
        width: 170px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
      }

      .number {
        color: #7a7e88;
        font-weight: 400;
        font-size: 16px;
        margin-top: 4px;
      }
    }

    .icon_box {
      width: 20px;
      height: 20px;
      margin-left: 20px;
    }
  }
}

.no_data {
  width: 100%;
  height: 112px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  box-sizing: border-box;
  padding: 24px;
  box-sizing: border-box;
  justify-content: space-between;

  .left {
    height: 100%;
    display: flex;
    align-items: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 20px;
    color: #7a7e88;

    .img {
      width: 64px;
      height: 64px;
      object-fit: cover;
    }
  }

  .el-button {
    width: 88px;
    height: 32px;
    background: #594fee;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
}
</style>
